<template>
	<div class="other_page_r">
		<el-card class="card_box">
			<div slot="header" class="card_title">
				<span>资讯管理/文章管理</span>
			</div>
			<div>
				<el-form :inline="true" :model="searchInfo" class="demo-form-inline">
					<el-form-item label="作者名称">
						<el-input
							class="custom_input_180"
							v-model="searchInfo.name"
							placeholder=""
						>
						</el-input>
					</el-form-item>
					<el-form-item label="资讯信息">
						<el-input
							class="custom_input_180"
							v-model="searchInfo.title"
							placeholder=""
						>
						</el-input>
					</el-form-item>
					<el-form-item label="分类">
						<el-select
							class="custom_input_80"
							v-model="searchInfo.category_id"
							placeholder=""
						>
							<el-option
								:label="item.name"
								:value="item.id"
								v-for="(item, index) in categoryList"
								:key="index"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="标签">
						<el-select
							class="custom_input_80"
							v-model="searchInfo.label_id"
							placeholder=""
						>
							<el-option
								:label="item.name"
								:value="item.id"
								v-for="(item, index) in labelList"
								:key="index"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="文章类型">
						<el-select
							class="custom_input_80"
							v-model="searchInfo.type"
							placeholder=""
						>
							<el-option label="文章" value="1"></el-option>
							<el-option label="快讯" value="2"></el-option>
							<!-- <el-option label="日历" value="3"></el-option>
							<el-option label="产业" value="4"></el-option> -->
							<el-option label="专栏" value="5"></el-option>
							<el-option label="活动" value="7"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="用户类型">
						<el-select
							class="custom_input_120"
							v-model="searchInfo.user_type"
							placeholder=""
						>
							<el-option label="普通用户" value="1"></el-option>
							<!-- <el-option label="个人用户" value="2"></el-option> -->
							<el-option label="企业用户" value="3"></el-option>
							<!-- <el-option label="项目用户" value="4"></el-option> -->
						</el-select>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="searchBtn">查询</el-button>
						<el-button type="primary" @click="reset">重置</el-button>
						<exportExcel :id="'exportTab'" :name="'资讯列表'"></exportExcel>
					</el-form-item>
				</el-form>
			</div>
			<div class="table_box">
				<tablec :tableData="tableData"></tablec>
			</div>
			<div
				class="flex justify_content_end aligin_items_c table_footer custom_btn"
			>
				<div>
					<pagination
						v-show="total > 0"
						:total="total"
						:page.sync="searchInfo.page"
						:limit.sync="searchInfo.size"
						@pagination="getDataList"
					/>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import tablec from "./tablec.vue";
import ExportExcel from "../../../components/ExportExcel.vue";
import {
	getInformationList,
	getCategoryList,
	getLabelList,
} from "@/api/article";
import { searchMixin } from "@/utils/searchMixin.js";
export default {
	mixins: [searchMixin],
	components: { tablec, ExportExcel },
	name: "",
	data() {
		return {
			tableData: [],
			total: 0,
			categoryList: [],
			labelList: [],
			searchInfo: {
				title: "",
				category_id: "",
				label_id: "",
				type: "",
				user_type: "",
				page: 1,
				size: 10,
				name: "",
			},
		};
	},
	created() {
		let _obj = JSON.parse(sessionStorage.getItem("messageListKey_m"));
		if (_obj) {
			Object.keys(this.searchInfo).forEach((key) => {
				this.searchInfo[key] = _obj[key] || this.searchInfo[key];
			});
		}
		this.getDataList();
		this.getCategoryListFun();
		this.getLabelListFun();
	},
	methods: {
		searchBtn() {
			this.searchInfo.page = 1;
			this.getDataList();
		},
		getLabelListFun() {
			getLabelList({})
				.then((res) => {
					if (res.code == 200) {
						this.labelList = res.data.data;
					} else {
						this.labelList = [];
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		getCategoryListFun() {
			getCategoryList({})
				.then((res) => {
					if (res.code == 200) {
						this.categoryList = res.data.data;
					} else {
						this.categoryList = [];
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		getDataList() {
			let _data = Object.assign(this.searchInfo, {});
			sessionStorage.setItem("messageListKey_m", JSON.stringify(_data));
			const that = this;
			getInformationList(this.searchInfo)
				.then((res) => {
					console.log(res);
					if (res.code == 200) {
						that.tableData = res.data.data;
						that.total = res.data.total;
					} else {
						that.tableData = [];
						that.total = 0;
					}
					this.loading = false;
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped></style>
